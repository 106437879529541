<template>
  <b-overlay
    bg-color="#f8f8f8"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
    class="min-vh-100"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0 justify-content-center">
        <div class="tbb-bg-image">
          <b-img
            :src="bgImage"
            alt="BeautiBook - Login"
          />
        </div>

        <!-- Brand logo-->
        <b-link class="brand-logo">
          <tbb-logotype />
          <h2 class="text-white tbb-logo mb-0">
            <span class="font-weight-light">Beauti</span>Book
          </h2>
        </b-link>
        <!-- /Brand logo-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { mapActions } from 'vuex'
import { APPS_STYLIST_DEFAULT } from '@/router/routes/routes-path'
import { SUBSCRIPTION_PLANS } from '@/router/routes/routes-names'

export default {
  data() {
    return {
      success: false,
      verifying: true,
    }
  },
  async mounted() {
    const subscribed = await this.verifySubscription()

    this.success = subscribed
    this.verifying = false

    if (subscribed) {
      this.$auth.setSubscribed()

      this.$router.push({ path: APPS_STYLIST_DEFAULT })
    } else {
      this.$router.push({ name: SUBSCRIPTION_PLANS })
    }
  },
  methods: {
    ...mapActions('SubscriptionStoreModule', [
      'checkStylistSubscriptionStatus',
    ]),

    async verifySubscription() {
      const success = this.$route.params.success === 'success'

      if (!success) {
        return false
      }

      const response = await this.checkStylistSubscriptionStatus()
      const { subscription } = response.data
      return subscription && ['active', 'trialing'].indexOf(subscription.status) >= 0
    },
  },
}
</script>
